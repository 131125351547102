.timesheet-row {
  display: flex;
  margin-bottom: 24px;
}

.timesheet-col-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4px;
  margin-right: 16px;
}
.timesheet-col-left--no-margin {
  margin-right: 0
}
.timesheet-col-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.timesheet-col-left h3,
.timesheet-col-center h3 {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.timesheet-day-total {
  text-align: left;
  font-size: 14px;
  margin: 0;
}

.timesheet-financial-data {
  padding-right: 10px;
}