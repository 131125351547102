.header {
  background-color: #fafafa;
  padding: 12px 12px 12px 24px;
}

.header p {
  margin: 0;
  max-width: 110px;
}

.invoice-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.paid-invoice {
  background-color: rgba(4, 193, 156, 0.1);
}

.active-invoice {
  background-color: rgba(0, 141, 248, 0.1);
}

.overdue-invoice {
  background-color: rgba(176, 12, 79, 0.1);
}

.draft-invoice {
  background-color: rgba(0, 0, 0, 0.1);
}

.sent-invoice {
  background-color: rgba(255, 193, 7, 0.1);
}

.payments .ant-form-item {
  margin-bottom: 0;
}

.status-tag {
  border: 0px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  width: fit-content;
  min-width: 4rem;
  font-weight: bold;
  margin: 0;
}

.status-tag.paid-invoice {
  background-color: rgba(4, 193, 156, 0.3);
  color: rgba(4, 193, 156, 1);
}

.status-tag.active-invoice {
  background-color: rgba(0, 141, 248, 0.3);
  color: rgba(0, 141, 248, 1);
}

.status-tag.overdue-invoice {
  background-color: rgba(176, 12, 79, 0.3);
  color: rgba(176, 12, 79, 1);
}

.status-tag.draft-invoice {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
}

.status-tag.sent-invoice {
  background-color: rgba(255, 193, 7, 0.3);
  color: rgba(255, 193, 7, 1);
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0;
}

.invoice-menu {
  position: absolute;
  right: 0;
  top: -5px;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 36px;
}

.invoice-line-items td,
.invoice-line-items th {
  padding: 2px 6px;
  border-bottom: 1px solid #fafafa;
}

.invoice-line-items {
  background-color: #fff;
  margin: 0 auto;
}

.invoice-item .invoice-table thead th {
  font-weight: bold;
}

.role-group-row,
.invoice-item .invoice-table tfoot tr {
  background-color: rgb(245, 245, 245);
}

.download-invoice-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.download-invoice-btn {
  max-width: 10rem;
}

.invoice-link .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: #000;
}

.invoice-line-item-amount > .ant-input-number-input-wrap > .ant-input-number-input {
  text-align: right;
  padding-right: 0;
}
