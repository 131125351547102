.image-by-height {
  width: auto;
}
.image-by-height:before {
  content: none;
}
.image-by-height > svg {
  height: inherit;
}
.image-by-height > img {
  height: inherit !important;
  vertical-align: top !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;
  max-height: none !important;
  border: 0 !important;
  opacity: 0 !important;
}
body {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  will-change: scroll-position;
}
.f-w-6 {
  font-weight: 600;
}
.text,
p {
  font-size: 12px;
  line-height: 150%;
}
.text-ico {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.text-ico .icon {
  width: 40px;
  display: block;
  margin-left: 16px;
}
@media (max-width: 540px) {
  .text-ico .icon {
    margin-left: 12px;
    width: 34px;
  }
}
.text-xl {
  font-size: 24px;
}
@media (max-width: 540px) {
  .text-xl {
    font-size: 16px;
  }
}
.color-white-o {
  color: rgba(255, 255, 255, 0.72);
}
.color-white-o-xl {
  color: rgba(255, 255, 255, 0.56);
}
.container {
  padding: 0 45px;
  width: 100%;
}
@media (max-width: 720px) {
  .container {
    padding: 0 25px;
  }
}
.screen {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}
.main {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  /* animation: shrink 10s ease-out; */
  /* animation-fill-mode: forwards; */
  background-size: cover !important;
  background-position: center;
  background-attachment: fixed;
}
.main > img {
  animation: shrink 10s ease-out;
  animation-fill-mode: forwards;
}
@keyframes shrink {
  0% {
    width: 103%;
    height: 103%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}
.main::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: linear-gradient(108.82deg, rgba(0, 0, 0, 0.5) 23.42%, rgba(0, 0, 0, 0.5) 69.31%);
  /* background: linear-gradient(108.82deg, #000 23.42%, rgba(0, 0, 0, 0) 69.31%); */
}
.background-img { 
  position: absolute; 
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  top: 0; 
  left: 0; 
}
.card {
  background: rgba(89, 89, 89, 0.24);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  padding: 40px;
  width: 100%;
  max-width: 480px;
}
@media (max-width: 540px) {
  .card {
    padding: 30px;
  }
}
.card p {
  margin-top: 10px;
}
.main-background figcaption {
  position: absolute;
  bottom: 14px;
  left: 20px;
  z-index: 1;
  font-weight: 600;
  font-size: 12px;
}
.welcome-page {
  height: 100%;
}
.welcome-page .container {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 87px;
  padding-bottom: 40px;
}
@media (max-width: 540px) {
  .welcome-page .container {
    padding-top: 40px;
  }
}
.welcome-page__card {
  z-index: 3;
  margin-bottom: 40px;
}
.welcome-page__card p, .welcome-page__card h2 {
  color: #fff;
}
.welcome-page__clock {
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  margin-left: auto;
  margin-top: auto;
}
@media (max-width: 540px) {
  .welcome-page__clock {
    margin-right: auto;
  }
}
.clock {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 182px;
  height: 182px;
  border-radius: 50%;
  z-index: 1;
}
.clock * {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
  margin: 0;
  padding: 0;
  outline: 0;
}
.clock::before {
  content: '';
  position: absolute;
  top: 11px;
  right: 11px;
  bottom: 11px;
  left: 11px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.clock__circle {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
}
.clock__circle circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s ease-in-out;
  stroke: #fff;
  stroke-width: 4px;
}
.clock__container {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.clock__row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.clock__col {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.clock__col span {
  color: #fff;
}
.clock__col:first-child {
  text-align: right;
  padding-right: 12px;
  margin-right: 12px;
}
.clock__col:first-child::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -1px;
  width: 1px;
  height: 53px;
  background: rgba(255, 255, 255, 0.72);
}
.clock__time {
  font-size: 36px;
  line-height: 89%;
}
.clock__date {
  display: block;
  font-size: 14px;
  margin-top: 14px;
}
.clock__utc {
  text-align: center;
  display: block;
  font-size: 9px;
  color: rgba(255, 255, 255, 0.56);
  margin-top: 10px;
}
.welcome-page__caption {
  position: absolute;
  bottom: 74px;
  color: #fff;
  z-index: 3;
}

.welcome-page__caption p {
  font-size: 14px;
}
