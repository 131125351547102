#chat-container {
  height: 100%;
  font-family: 'Proxima Nova';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  .button-without-bg {
      background: none !important;
  }

  .logo {
      height: 28px;
      margin: 16px 0 16px 8px;
  }

  .site-layout-background {
      background: #fff;
  }

  .site-layout-header {
      background: #000;
      text-align: right;
      padding: 0 16px;
  }

  .site-layout-header a {
      color: #fff;
  }

  .site-layout-header-spacing {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .header-search-input {
      border-radius: 8px;
      border: 0;
      color: rgba(255, 255, 255, 0.4);
      background: #1a1c1f;
  }

  .header-search-input>input {
      color: rgba(255, 255, 255, 0.4);
      background: #1a1c1f;
  }

  /* make table row cursor pointer if its clicable */
  .clickable-row tbody tr {
      cursor: pointer;
  }

  /* custom container that works with Row and Col front antd */

  .custom-container-multiple-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
  }

  .custom-container-multiple-wrapper>.custom-container {
      flex: 1;
  }

  .custom-container {
      padding: 8px 16px;
      background: #fff;
      height: 100%;
      border-radius: 8px;
  }

  .custom-container--mb {
      margin-bottom: 16px;
  }

  .cursor-pointer {
      cursor: pointer;
  }

  /* ant design override */

  .ant-layout {
      height: inherit;
      background: #FFF;
  }

  .ant-page-header-heading {
      align-items: center;
  }

  .my-reaction {
      border-inline: 2px solid rgb(51, 144, 236);
  }

  .custom-container {
      .ant-tabs-tab-btn {
          font-size: 16px;
          font-weight: 600;
          color: #1D1D1D;
          opacity: 0.5;
      }
  }

  .ant-table-container {
      overflow: scroll;
  }

  .ant-card {
      border-radius: 8px;
  }

  .ant-input,
  .ant-select-selector {
      border-radius: 6px !important;
  }

  .ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl)>.ant-select-selector {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
  }

  .red-text.ant-progress .ant-progress-text {
      color: red;
  }

  @media (max-width: 600px) {
      .ant-tabs-content-holder {
          padding: 5px 0 !important;
      }
  }


  @media (max-width: 365px) {
      .EmojiPickerReact {
          width: 98% !important;
      }
  }

  .EmojiPickerReact {
      height: 400px !important;
      width: 280px !important;

  }

  .epr-category-nav {
      padding: 0 10px !important;
  }

  .epr-preview {
      display: none !important;
  }

  .epr-emoji {
      width: 34px !important;
  }

  .epr-emoji-category-content {
      margin: 0 10px !important;
      grid-template-columns: repeat(auto-fill, 34px) !important;
  }

  .epr-emoji-img {
      padding: 6px !important;
      width: 34px !important;
      height: 34px !important;
  }

  .editor :global(.public-DraftEditor-content) {
      height: 100%;
  }

  .mention {
      background: rgba(var(--sk_highlight_accent, 29, 155, 209), .1);
      border-radius: 3px;
      color: rgba(var(--sk_highlight, 18, 100, 163), 1);
      padding: 0 2px 1px;
  }

  .mention:hover {
      background: rgba(var(--sk_highlight_accent, 29, 155, 209), .2);
      color: rgba(var(--sk_highlight_hover, 11, 76, 140), 1);
  }

  .you-mentioned {
      background: #f2c74466;
  }

  .you-mentioned:hover {
      background: #f2c74466;
  }

  .ant-tabs-nav {
      max-height: 100vh;
      overflow: hidden;
  }

  .ant-tabs-extra-content {
      position: sticky;
      top: 0;
      z-index: 1000;
      background: white;
  }

  .ant-tabs-nav-list {
      overflow: auto;
  }

  .ant-tabs-nav-list>.ant-tabs-tab {
      padding: 8px !important;
      margin-right: 8px !important;
      margin-left: 8px !important;
  }

  .ant-tabs-nav-list>.ant-tabs-tab-disabled {
      margin: 0 !important;
      margin-bottom: 6px !important;
      margin-top: 6px !important;
      padding: 0 !important;
  }

  .ant-tabs-tab-active {
      background: #4F67FF;
      border-radius: 8px;
  }

  #dashboard-main-container {
      padding: 18px 24px;
      background: #ECEFF3;
  }

  .ant-tabs-content-holder>.ant-tabs-content-left {
      border-radius: 8px;
      height: 100%;
  }


  .ant-tabs-tab-disabled:first-child {
      display: none;
  }

  .backshadow {
      box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  }

  .ant-tabs-ink-bar {
      display: none;
      border-top-right-radius: 10px !important;
      border-top-left-radius: 10px !important;
  }

  .ant-typography-edit-content {
      margin: 0 !important;
      left: 0 !important;
      width: max-content !important;
      overflow: visible !important;
      overflow-wrap: normal !important;
  }

  .emoji-picker-container {
      pointer-events: none;
      animation: HideEmojiPicker .3s ease-in-out;
      transition: all .3s ease-in-out;
      height: 460px;
      opacity: 0;
  }

  @keyframes HideEmojiPicker {
      0% {
          pointer-events: all;
      }

      99% {
          pointer-events: all;
      }

      100% {
          pointer-events: none;
      }
  }

  @keyframes ShowEmojiPicker {
      0% {
          opacity: 0;
      }

      100% {
          opacity: 1;
      }
  }

  .emoji-icon-container:hover>.emoji-picker-container {
      opacity: 1;
      pointer-events: all;
      transition: all .2s ease-in-out;
      animation: ShowEmojiPicker .2s ease-in-out;
  }

  .itemdrop-zone {
      position: absolute;
      top: 9px;
      left: 10px;
      right: 10px;
      bottom: 9px;
      background: rgba(255, 255, 255, 0.825);
      animation: HideFileDropZone .2s ease-in-out;
      opacity: 0;
      border: 1px dashed #555555;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .2s ease-in-out;
      pointer-events: none;
      z-index: -9999;
  }

  .hide-dropzone {
      transition: all .2s ease-in-out;
      animation: HideFileDropZone .2s ease-in-out;
  }

  .show-dropzone {
      opacity: 1;
      pointer-events: all !important;
      transition: all .2s ease-in-out;
      animation: ShowFileDropZone .2s ease-in-out;
      z-index: 99;
  }

  .itemdrop-zone>span {
      font-weight: 600;
      font-size: 20px;
  }

  @keyframes HideFileDropZone {
      0% {
          z-index: 99;
          pointer-events: all;
      }

      99% {
          z-index: 99;
          pointer-events: all;
      }

      100% {
          z-index: -99;
          pointer-events: none;
      }
  }

  @keyframes ShowFileDropZone {
      0% {
          z-index: 99;
          opacity: 0;
      }

      100% {
          opacity: 1;
      }
  }

  .custom-project-button:hover {
      svg {
          color: #4F67FF;
      }
  }

  .custom-project-button {
      box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
      border-radius: 6px;
      color: #4D546E;
      display: flex;
      align-items: center;
      font-weight: 600 !important;
      font-size: 14px;
      height: 36px;


      .ant-select-selector {
          height: 100%;

          .ant-select-selection-item {
              display: flex;
              align-items: center;
          }
      }
  }


  .ant-badge {
      position: static;
  }

  .ant-badge.project-badge {
      position: relative;
  }

  .ant-row-middle {
      justify-content: space-between;
      align-items: center;
  }

  .ant-badge-count {
      margin: 0;
      border-radius: 5.68421px;
      min-width: auto;
      width: 16px;
      height: 16px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
      font-weight: 700;
      background: #E45851 !important;
      font-size: 8px;
  }

  .chat-tab-channel {
      flex: none;
  }

  .ant-tabs-tab-active {
      .adv-project-subtitle {
          color: white !important;
      }

      .chat-tab-channel>span {
          color: white !important;
      }
  }

  .custom-container {
      .tab-badges {
          display: none !important;
      }
  }
}

.ant-tabs-tab {
  user-select: none !important;

  div {
      user-select: none !important;
  }

  span {
      user-select: none !important;
  }
}

.selected-item {
  background: #4F67FF !important;
  padding: 8px !important;
  border: none !important;

  .adv-project-subtitle {
    color: #FFFFFF !important;
  }
}

#dashboard-sidebar {
  position: relative;
}

.dashboard-container {
  height: 100%;
}

#dashboard-main-container {
  padding: 24px;
  background: #ECEFF3 !important;
}

.sidebar {
  overflow-y: auto;

}

.project-item {
  padding: 8px;
  border-radius: 8px;
}


.project-item-adv {
  padding: 7px;
  border: 1px solid #D9DCE3;
  margin-bottom: 8px;
}

.filter-projects-button:hover>.filter-projects-container {
  left: 0;
  opacity: 1;
  pointer-events: all;
}

.select-filter {

  .ant-select-selector {
    height: 32px !important;
    align-items: center;
  }
}

.create-projects-button {
  cursor: pointer;
  padding: 10px 0;
  margin-left: 8px;
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  border-radius: 100%;
  font-size: 14px;
  transition: all .3s ease-in-out;

  background: #4F67FF;

  .plus-icon {
    color: white !important;
  }
}


.ant-dropdown-menu {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.create-projects-button:hover {
  background: #2845ff;

  .plus-icon {
    color: white !important;
  }
}

.filter-projects-button {
  cursor: pointer;
  padding: 10px 0;
  margin-left: 8px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
  border-radius: 6px;
  color: #4D546E;
  font-size: 14px;
  transition: all .3s ease-in-out;
}

.filter-reset-all {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
  color: #4F67FF;
  margin-bottom: 16px;
}

.filter-button-active {
  border: 1px solid #4F67FF;

  .filter-icon {
    color: #4F67FF;
  }
}

.filter-projects-button:hover {
  position: absolute;
  width: 96%;
  right: 2%;
  padding: 0 !important;
  margin: 0;
  color: #4F67FF;
  border: 1px solid #4F67FF;

  .filter-text::before {
    content: "Filter";
    margin-left: .5rem;
  }
}

#dashboard-sidebar {
  border-right: 1px solid rgba(0, 0, 0, .12);
}

.search-filter-input {

  input {
    background: #ECEFF3;
  }
}

.ant-tabs-tab .anticon {
  margin-right: 0;
}

.filter-projects-container {
  left: -25px;
  pointer-events: none;
  opacity: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(53, 53, 65, 0.16), 0px 4px 8px rgba(53, 53, 65, 0.12);
  padding: 12px 12px 16px 12px;
  color: #1D1D1D !important;
  transition: all .3s ease-in-out;

  .ant-switch-handle {
    top: 3px;
    left: 3px;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 21px);
  }

  select {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  select {
    background-image: url("../../static/images/icon-select.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
  }
}