/* @media only screen and (min-width: 800px) { */
.ant-layout-content {
  position: relative;
}

.sub-submenu {
  justify-content: flex-start !important;
  align-items: normal;
}

.sub-submenu>.ant-menu-title-content {
  display: block !important;
  width: auto !important;
  height: auto !important;
}

.project-list-container {
  position: absolute;
  top: 0;
  z-index: 100;
}

.ant-menu-submenu-title {
  padding: 0 16px !important;
}

.ant-menu-item>svg {
  width: 24px !important;
  height: 24px !important;
  margin-left: 7px !important;
}


.ant-menu-item>span {
  width: 0 !important;
  height: 0 !important;
  /* display: none !important; */
}

.ant-menu-item {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* } */

.ant-layout-sider-collapsed {
  flex: 0 0 56px !important;
  width: 56px !important;
  max-width: auto !important;
  min-width: auto !important;
}

.ant-card-head-title {
  font-weight: 600 !important;
}

/* @media only screen and (max-width: 800px) {
  .custom-container {
    border-radius: 0;
    padding: 0;
    flex: auto;
    display: flex;
    flex-direction: column;
  }

  #ddc-chat-direct-chats {
    flex: auto;
  }

  .ant-layout-sider-children>.ant-menu-root>.ant-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 8px !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    font-weight: 700;
    font-size: 13px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    font-family: "Proxima Nova" !important;
  }

  .ant-layout-sider-children>.ant-menu-root>.ant-menu-item-selected {
    background: none !important;
  }

  .ant-layout-sider-children>.ant-menu-root>.ant-menu-item>svg {
    height: 25px;
  }

  .ant-menu-item-selected>svg {
    color: #4F67FF !important;
  }


  .ant-menu-item-selected>span>a {
    color: #4F67FF !important;
  }

  .ant-menu-item>.ant-menu-title-content {
    margin: 0 !important;
  }

  .ant-layout-sider-children>.logo {
    display: none;
  }

  .ant-layout-sider-children>.ant-menu-root {
    display: flex;
    justify-content: space-evenly;
  }

  .ant-layout-sider-children {
    height: auto;
  }

  .ant-layout.ant-layout-has-sider>.ant-layout,
  .ant-layout.ant-layout-has-sider>.ant-layout-content {
    margin-left: 0 !important;
    width: auto;
  }

  .ant-layout-sider {
    position: static !important;
    width: 100% !important;
    max-width: 100% !important;
    height: fit-content !important;
    flex: none !important;
  }

  .ant-layout-has-sider {
    flex-direction: column-reverse !important;
    justify-content: space-between !important;
    min-height: 100vh;
  }

  .screen {
    min-height: 100% !important;
  }

  .ant-tabs-ink-bar {
    background: #4F67FF !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 14px;
    font-family: "Proxima Nova" !important;
  }

  .ant-modal {
    max-width: calc(100vw - 32px);
    ;
    margin: 0 auto;
  }

  .modal-search-input-wrapper>div>input {
    width: auto;
    max-width: 550px;
    min-width: 300px;
  }

  .project-select {
    max-width: 250px;
  }

  .project-list-container {
    padding: 0 16px;
    font-family: "Proxima Nova" !important;
    border-bottom: 1px solid #ECECEC;
  }

  .project-list {
    padding: 0 16px;
    font-family: "Proxima Nova" !important;
  }

  .projects-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .projects-list-header-title {
    font-weight: 700;
    font-size: 21px;
    color: #1D1D1D;
  }

  .project-list {
    margin-top: 5px;
    padding: 0;
  }

  .projects-name {
    font-weight: 600;
    font-size: 14px;
    color: #1E1F20;
  }

  .project-mobile-header {
    background: #fff;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }

  .ant-tabs-tab-active {
    background: none !important;
  }

  .project {
    margin-bottom: 12px;
  }

  .ant-row {
    margin: 0 !important;
  }

  .ant-col-24 {
    padding: 0 !important;
  }

  .chat-tab-in-mobile {
    top: 121px !important;
    height: calc(100vh - 195px) !important;
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }
} */


@media only screen and (max-width: 400px) {
  .tasks-table-header-wrap {
    flex-shrink: 1 !important;
    flex-wrap: wrap !important;
  }


  .tasks-table-header {
    flex-wrap: wrap;
  }

  .modal-search-input-wrapper>div>input {
    font-size: 12px;
    width: auto;
    min-width: 210px;
  }

  .modal-search-input-wrapper>div>input:placeholder-shown {
    text-overflow: ellipsis;
    overflow: auto;
  }
}