.link-container {
  display: flex;
  align-items: center;
}

.link-container > .ant-input {
  border: 0;
  padding: 4px;
}

.link-display {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding: 0 4px;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.domain {
  color: black;
}

.path {
  color: gray;
}

.edit-button {
  opacity: 0;
  transition: opacity 0.3s;
}

.link-display:hover .edit-button {
  opacity: 1;
}
