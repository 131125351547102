.chat-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.chat-divider-badge {
  background: white;
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;
  color: inherit;
}

.chat-divider-badge.new-divider-badge {
  border: 1px solid red;
  color: red;
}

.chat-divider-new {
  color: red;
  font-weight: bold;
  text-align: center;
}

.chat-divider.new-divider {
  border-color: red;
}

.scroll-to-bottom-btn {
  position: fixed;
  bottom: 140px;
  right: 80px;
  z-index: 1;
  width: 56px;
  height: 56px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007aff;
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, transform 0.2s;
}

.scroll-to-bottom-btn:hover {
  background: #005ecb;
  transform: scale(1.1);
}

.scroll-to-bottom-btn:active {
  transform: scale(1);
}

.chat-message .ant-comment-inner {
  padding: 5px;
}
.chat-message .ant-comment-actions {
  margin-top: 0;
}
