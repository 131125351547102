.timesheet-row-form-container {
  margin-bottom: 20px;
}

.timesheet-form-row-add {
  display: flex;
  justify-content: flex-end;
}

.timesheet-form-row-description {
  flex-grow: 1 !important;
}

.timesheet-form-row-category {
  width: 198px;
}

.timesheet-form-row-task {
  width: 198px;
}

.timesheet-form-row-project {
  width: 198px;
}

.timesheet-form-row-project--narrow {
  width: 160px;
}

.timesheet-form-row-button {
  display: flex;
  align-items: flex-end;
}

.timesheet-form-row-time {
  width: 275;
}

.timesheet-form-row-time__container {
  display: flex;
  flex-shrink: 0;
}

.timesheet-form-row-project h4 {
  font-weight: bold;
}

.timesheet-row-project-category {
  display: flex;
}

.timesheet-form-vertical {
  flex-direction: column;
}

.timesheet-form-vertical > div {
  margin-bottom: 20px;
}