.phase-header-value {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  height: 40px;
}

.phase-header-value--non-interactive {
  cursor: initial
}

.phase-header-options {
 margin-left: auto;
 margin-right: 20px; 
}

.phase-dates-container {
  display: flex;
  align-items: center;
}

.phase-header-icon {
  margin-left: 10px;
  color: #4f67ff;
  cursor: pointer;
}