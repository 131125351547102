#settings-tabs {
  .button-figma-desing {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(77, 84, 110, 0.12), 0px 0px 1px rgba(77, 84, 110, 0.4);
    border-radius: 6px;
    border: none;
    color: #4D546E;
    font-family: Proxima Nova;
  }


  .ant-space-item {
    overflow: visible;
  }

  #settings-tabs-tab-reports-header {
    margin-top: 12px;
  }

  #settings-tabs-tab-setting-header {
    margin-top: 12px;
  }

  #main-container-inner {
    padding: 16px 24px;
    background: #ECEFF3;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-page-header {
    background: #ECEFF3;
    padding: 0px 0 16px 0;
  }

  .ant-space-vertical {
    background: white;
    border-radius: 8px;
    padding: 24px 24px;
    flex: auto;
  }

  #sidebar-inner {
    .ant-row {
      font-family: Proxima Nova;
      font-size: 0.875rem;
      color: rgb(129 129 129 / 1) !important;

      svg {
        margin-right: 5px;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-nav-list {
      overflow: auto;
    }

    .ant-tabs-nav-list>.ant-tabs-tab {
      padding: 8px !important;
      margin-right: 8px !important;
      margin-left: 8px !important;
    }

    .ant-tabs-nav-list>.ant-tabs-tab-disabled {
      margin: 0 !important;
      margin-bottom: 6px !important;
      margin-top: 8px !important;
      padding: 0 !important;
    }

    .ant-tabs-tab-active {
      background: #4F67FF;
      border-radius: 8px;

      svg {
        color: white !important;
      }

      span {
        color: white !important;
      }
    }

  }

  .ant-tabs-content-holder {
    padding: 0 8px;
    max-height: 100%;
    overflow-y: auto;
  }

  #settings-tabs-panel-approvals {
    flex-wrap: nowrap;
  }

  .ant-page-header-heading {
    flex-wrap: wrap;
  }

  .ant-page-header-heading-left {
    flex-wrap: wrap;
  }
}