@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Proxima Nova';
  src: url('./static/fonts/ProximaNova-Bold.woff2') format('woff2'), url('./static/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./static/fonts/ProximaNova-Semibold.woff2') format('woff2'), url('./static/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('./static/fonts/ProximaNova-Regular.woff2') format('woff2'), url('./static/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  height: 28px;
  margin: 16px 0 16px 8px;
}

.site-layout-background {
  background: #fff;
}

.site-layout-header {
  background: #000;
  text-align: right;
  padding: 0 16px;
}

#sidebar-inner .ant-tabs-nav-operations {
  display: none;
}

.custom-form-block {
  font-family: 'Proxima Nova' !important;
}

.custom-form-block > .ant-form-item-row {
  display: block !important;
}

.custom-form-block .ant-form-item-required::before {
  content: '' !important;
}

.custom-form-block .ant-form-item-required::after {
  content: '' !important;
}

.custom-form-block .ant-select-selection-overflow {
  height: 48px !important;
}

.custom-form-block input {
  height: 48px !important;
}

.custom-form-block .ant-tag {
  font-family: 'Proxima Nova' !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 0 4px 0 2px;
  border: none;
  background: #eceff3;
  border-radius: 14px;
  padding-right: 4px;
}

.custom-form-block label {
  font-weight: 600;
}

.channel-modal .ant-modal-content {
  border-radius: 16px;
}

.channel-modal .ant-modal-header {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom: 1px solid #ececec !important;
}

.channel-modal .ant-modal-footer {
  display: flex;
  justify-content: space-between;
}

.channel-modal .ant-btn {
  height: 40px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.channel-modal .ant-btn-default {
  color: #4d546e;
}

.site-layout-header a {
  color: #fff;
}

.site-layout-header-spacing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-search-input {
  border-radius: 8px;
  border: 0;
  color: rgba(255, 255, 255, 0.4);
  background: #1a1c1f;
}

.header-search-input > input {
  color: rgba(255, 255, 255, 0.4);
  background: #1a1c1f;
}

/* make table row cursor pointer if its clicable */
.clickable-row tbody tr {
  cursor: pointer;
}

/* custom container that works with Row and Col front antd */

.custom-container-multiple-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom-container-multiple-wrapper > .custom-container {
  flex: 1;
}

.custom-container {
  padding: 8px 16px;
  background: #fff;
  border-radius: 8px;
}

.custom-container--mb {
  margin-bottom: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

/* ant design override */

.ant-btn,
.ant-tag {
  border-radius: 8px;
}

.ant-tabs-tab-btn {
  font-size: 16px;
}

.ant-table-container {
  overflow: scroll;
}

.ant-card {
  border-radius: 8px;
}

.ant-card .ant-form-item:last-child {
  margin-bottom: 3px;
}

.ant-input,
.ant-picker,
.ant-select-selector {
  border-radius: 6px;
}

.red-text.ant-progress .ant-progress-text {
  color: red;
}

.payroll-header .ant-page-header-heading-left {
  align-items: baseline;
}

.tab-badges {
  transition: width 0.2s ease-in-out;
}

.tab-badges.active {
  width: 26px;
}

.tab-badges .ant-badge {
  position: absolute;
  top: -2px;
  left: 10px;
}

.tab-badges .ant-badge-dot {
  transform: none;
}

.tab-badges .ant-badge-count {
  margin: -8px 0 0 -5px;
}

/* custom header */
.ant-page-header--custom {
  padding: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

/* button with custom icon alignment */
.ant-btn--custom-icon {
  display: flex;
  align-items: center;
}

.ant-btn--custom-icon svg {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}

.ant-table-container {
  overflow: hidden;
}

.table-row-disabled {
  color: rgba(0, 0, 0, 0.45);
}

#budget-planner .ant-table-container {
  overflow: initial !important;
}

#budget-planner .ant-table-summary {
  z-index: initial !important;
}

#budget-planner tbody > tr:hover > td {
  background-color: unset !important;
  color: unset !important;
}

.non-chat-table .ant-table-container {
  overflow: unset !important;
}

.nested-table .ant-table {
  margin: 0px !important;
}
