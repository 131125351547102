.recent-searches-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.recent-searches-list li {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  cursor: pointer;
}
