.modal-search-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-search-input-wrapper > div {
  display: flex;
  align-items: center;
}

.modal-search-input-wrapper > div > input {
  width: 900px;
  outline: none;
  border: none;
  margin-left: 10px;
  font-size: 18px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.5);
}

.modal-search-input {
  background: #1a1c1f;
}
