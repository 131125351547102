.search-input {
  width: 300px;
  margin-bottom: 16px;
  margin-right: 16px;
}

.search-select.ant-select .ant-select-selector {
  border-radius: 6px;
}

.search-select-industry {
  width: 150px;
  margin-left: 16px;
}
