.main-container {
    height: 100%;
    display: flex;
}

.left-container {
    flex: 1 1 0;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: beige;
}

.right-container {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.version {
    text-align: center;
    padding: 10px;
    color: #aaa;
}

.google-login-button {
    width: 100%;
}