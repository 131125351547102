/* .disable-hover-color .ant-table-row:hover > td {
  background: none !important;
} */

.task-table .ant-table-placeholder{
  display: none;
}

.task-table .anticon-check-circle:hover {
  transition: transform 0.1s ease-in-out;
  transform: scale(1.2);
}

.tasks-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  margin-top: 16px;
  padding: 8px;
  border-radius: 8px 8px 0 0;
  background: #fafafa;
  border: 1px solid #ddd;
}