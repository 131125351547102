.search-results-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-results-list li {
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.search-results-list li a {
  color: white;
  display: flex;
  margin-left: -16px;
  width: calc(100% + 16px);
  padding: 6px 0 6px 16px;
}

.search-results-list li a:hover {
  background-color: #252525;
}

.search-results-list li svg {
  margin-right: 12px;
}

.search-results-icon {
  width: 16px;
  height: auto;
}