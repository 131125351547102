.check-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 50%;
}

.check-container--non-interctive {
  cursor: initial;
}

.check-container--checked, .check-container--checked-non-interactive {
  background: rgb(146, 223, 146);
  border-color: rgb(146, 223, 146);
}

.check-container:hover {
  background: #f2f2f2;
  border-color: black;
}

.check-container--checked:hover {
  background: rgb(146, 223, 146);
  border-color: rgb(146, 223, 146);
}

.check-container:hover .checkmark {
  border-color: black;
}

.check-container--checked:hover .checkmark {
  border-color: white;
}

.check-container--non-interctive:hover {
  background: white;
}
.check-container--non-interctive:hover .checkmark {
  border-color: white;
}
.check-container--checked-non-interactive:hover {
  background: rgb(146, 223, 146);
  border-color: rgb(146, 223, 146);
}

.checkmark {
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 7px;
  margin-bottom: 2px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}

.check-container-animated { 
  font-size: 10px;
  color: white;
}
