.modal-search {
  background: #1a1c1f;
  border-radius: 8px;
  box-shadow: 0px 8px 12px 0px #1A1C1F99;
  padding: 18px 0;
  color: white;
  pointer-events: auto; 
}

.modal-search-input-container {
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-search-results-container {
  padding: 16px 0 0 16px;
}

.searches-header {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 24px;
  margin: 10px 0 0 0;
}