.portfolio-table .ant-table-tbody > tr > td {
  padding: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.portfolio-table .ant-table-tbody > tr > td .ant-select-selector {
  border: 0;
}

.portfolio-table .ant-form-item-control-input {
  min-height: auto;
}

.portfolio-table .ant-input,
.ant-select-selector {
  border-radius: 0;
}

.portfolio-table .ant-table-cell .ant-form-item {
  margin: 0;
}

.portfolio-item {
  color: #4d546e;
  font-weight: 600;
}

.portfolio-project {
  display: flex;
  padding: 3px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
}

.portfolio-table .ant-table-tbody > tr > .portfolio-code,
.portfolio-table .ant-table-tbody > tr > .portfolio-phase {
  padding: 0 5px;
}

.portfolio-value {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
  white-space: nowrap;
}

.portfolio-dot {
  margin: 0 3px;
}
